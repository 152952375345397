<template>
  <div>
    <b-card title="Romatem Çağrı Portalı 🚀"
      ><img src="@/assets/home.jpg" style="width: 40%" alt="" />
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
};
</script>

<style></style>
